import React from 'react';
import '../App.css';
import { Button, ButtonDev, ButtonRelease, ButtonYoutube } from './Button';
import './HeroSection.css';
// import video from '../public/videos/video-1.mp4';
import video from '../public/videos/video-3.mp4';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src={video}
      autoPlay loop muted/>
      <h1>ADVENTURE AWAITS</h1>
      <p>What are you waiting for?</p>
      <div className='hero-btns'>
        <ButtonDev
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          Learn More
        </ButtonDev>
        <ButtonYoutube
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          // onClick={console.log('hey')}
        >
          WATCH TRAILER <i className='far fa-play-circle' />
        </ButtonYoutube>
      </div>
    </div>
  );
}

export default HeroSection;


// function HeroSection() {
//   return (
//     <div className='hero-container'>
//       <video loop autoPlay muted>
//         <source src ='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
//         type='video/mp4'
//         />
//       </video>
//       <h1>ADVENTURE AWAITS</h1>
//       <p>What are you waiting for?</p>
//       <div className='hero-btns'>
//         <Button
//           className='btns'
//           buttonStyle='btn--outline'
//           buttonSize='btn--large'
//         >
//           GET STARTED
//         </Button>
//         <Button
//           className='btns'
//           buttonStyle='btn--primary'
//           buttonSize='btn--large'
//           onClick={console.log('hey')}
//         >
//           WATCH TRAILER <i className='far fa-play-circle' />
//         </Button>
//       </div>
//     </div>
//   );
// }

// export default HeroSection;