import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>This is my first game as an aspiring developer, feel free to check out how the game was developed!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/code2.jpg'
              text='The game was coded in Python using PyGame'
              label='Code'
              path='/development'
            />
            <CardItem
              src='images/tiled.jpg'
              text='The maps were designed in Tiled with custom and pre-made assets'
              label='Map Design'
              path='/development'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/photoshop.jpg'
              text='Player character, monsters, magic, and weapons were all drawn in Adobe Photoshop'
              label='Graphic Artist'
              path='/development'
            />
            <CardItem
              src='images/github.jpg'
              text='Git was used for version control during development and is hosted on Github with 131+ commits so far'
              label='Version control'
              path='/development'
            />
            <CardItem
              src='images/aws.jpg'
              text='This site is powered by AWS and utilizes Cloudfront, Route 53, and S3!'
              label='AWS Web Hosting'
              path='/development'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;