import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';

export default function Development() {
    return (
        <>
        <Cards/>
        <Footer />
        </>
    
    );
}