import React from 'react';
import '../../App.css';
import BuyMeACoffeeWidget from '../BuyMeACoffeeWidget';
import Footer from '../Footer';
import '../HeroSection.css';
import { Button, ButtonDev, ButtonRelease } from '../Button';
// import video from '../public/videos/video-1.mp4';
import video from '../../public/videos/video-3.mp4';

export default function Donate() {
    return (
        <><div className='flex-wrapper'>
            <div>
            <h1 className='cards'>Dontations to support my journey are appreciated</h1>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'}}>
            <BuyMeACoffeeWidget/>
            </div></div>
            <Footer />
        </div>
        </>
      );
//     return (
//     <>
//     <div className='hero-container'>
//         <h1 className='sign-up'>Donations appreciated to support my learning journey</h1>
//         <div>
//         <BuyMeACoffeeWidget className='sign-up' />
//         </div>
//     </div>
//     <Footer /></>
//     );
}