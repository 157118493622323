import React from 'react';
import NavBar from './components/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Development from './components/pages/Development';
import Donate from './components/pages/Donate';
import Release from './components/pages/Release';
import LearnMore from './components/pages/LearnMore';
// import video from './/public/videos/video-3.mp4';

function App() {
  return (
    <>
      <Router>
        <NavBar/>
        <Routes>
        <Route path='/' exact element={<Home/>} />
        <Route path='/learnmore' exact element={<LearnMore/>} />
        <Route path='/development' exact element={<Development/>} />
        <Route path='/donate' exact element={<Donate/>} />
        <Route path='/release' exact element={<Release/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;