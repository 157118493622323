import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './NavBar.css';

function NavBar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        } else{
            setButton(true);
        }
    };

    useEffect(() => {
      showButton();
    }, []);
    
    window.addEventListener('resize', showButton);
    return (
        <>
          <nav className='navbar'>
            <div className='navbar-container'>
              <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                KAMUI
                <i class='fa fa-gamepad' />
              </Link>
              <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
              </div>
              <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                  <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                    Home
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/learnmore' className='nav-links' onClick={closeMobileMenu}>
                    Learn More
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/development'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    Development
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/release'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    Release
                  </Link>
                </li>
    
                <li>
                  <Link
                    to='/donate'
                    className='nav-links-mobile'
                    onClick={closeMobileMenu}
                  >
                    Donate
                  </Link>
                </li>
              </ul>
              {button && <Button buttonStyle='btn--outline'>Donate</Button>}
            </div>
          </nav>
        </>
      );

}

export default NavBar