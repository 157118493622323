import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../Cards.css';

export default function LearnMore() {
    return (
        <>
        <div className='flex-wrapper'>
        <div className='cards'>
        <h1>The story behind the game</h1>
        <p>Kamui is a 2D RPG developed with PyGame for Windows pc.  I decided upon the idea during my tenure unloading trucks at Walmart towards the end of my computer science degree.  I wanted to make a project that displayed my skills and felt creating a game, developing assets, and building a companion website would be the proper way to develop and showcase my talents as an aspiring developer. 
</p>
        <h1>Gameplay</h1>
        <p>The underlying gameplay of Kamui is quite straightforward.  On each level, there are enemies and bosses to fight that give exp used for leveling up your stats, however defeating all the enemies or leveling up is not necessary for completing levels.  Each level has a pad with the word end abbreviated on it in the form of a capital E that represents the end of the level.  Stepping on the pad will prompt the player if they want to continue to the next level or quit the game</p>
        <h1>Controls</h1>
        <p>The controls for the game are currently keyboard based.  To switch physical weapons the user presses 'q' and for magical abilities, it's 'e'.  To attack physically the user presses the space bar while for magical abilities it's left control.  The letter 'm' opens up the upgrade menu where users can make selections with the arrow keyboard and confirm them with the enter key.  The keyboard arrows are used to direct player movement.</p>
        </div>
        <Footer/>
        </div>
        </>
    );
}

