import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../Cards.css';

export default function Release() {
    return (
        <>
        <div className='flex-wrapper'>
        <div className='cards'>
        <h1>Release date: TBA</h1>
        </div>
        <Footer/>
        </div>
        </>
    );
}



